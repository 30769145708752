/**
 * List surveys.
 */
import { apiGet } from '@/util/api';

export default {
  name: 'Surveys',
  data() {
    return {
      loading: true,
      surveys: [],
      surveysTableFields: [
        {
          key: 'name',
          label: this.$t('surveys_list_survey_name'),
        },
      ],
    };
  },
  async created() {
    // Get the list of surveys from the API.
    try {
      this.surveys = await apiGet('/survey');
    } catch (error) {
      this.$bvToast.toast(this.$t('surveys_error_get_surveys'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    } finally {
      this.loading = false;
    }
  },
};
